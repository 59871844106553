import React, { useEffect, useState } from "react"

import SEO from "../../components/seo"
import { Form } from "react-bootstrap"

import imgExampleBeNl from "../../assets/images/carte-grise_BENL.jpg"
import imgExampleNl from "../../assets/images/carte-grise_NL.jpg"
import imgExampleLu from "../../assets/images/carte-grise_LU.jpg"

import instance from "../../components/api/httpclient"
import { navigate, useIntl } from "gatsby-plugin-intl"

const AddCar = props => {
  const [vin, setVin] = useState("")
  const [licence_plate, setLicencePlate] = useState("")
  const [licencePlatePlaceholder, setLicencePlatePlaceholder] = useState(
    "AA-123-A"
  )
  const [vinError, setVinError] = useState(false)
  const [carDontExistError, setCarDontExistError] = useState(false)
  const [licensePlateError, setLicensePlateError] = useState(false)
  const [selectedTab, setSelectedTab] = useState(0)

  const intl = useIntl()

  const country = (() => {
    switch (intl.locale) {
      case "fr-BE":
        return "BE"
      case "fr-LU":
        return "LU"
      case "nl-NL":
        return "NL"
      case "nl-BE":
        return "BE"
      default:
        return "NL"
    }
  })()

  const licencePlatePrefix = (() => {
    switch (intl.locale) {
      case "fr-BE":
        return "B"
      case "fr-LU":
        return "LU"
      case "nl-NL":
        return "NL"
      case "nl-BE":
        return "B"
      default:
        return "NL"
    }
  })()

  const findCar = (event, field, value) => {
    let data = {}
    data[field] = value

    if (notValidField(field, value) === false) {
      instance
        .get(`/car/` + field + "/" + value, {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        })
        .then(response => {
          if (undefined === response.data.error) {
            if (response.data.car === null) {
              setCarDontExistError(true)
            } else {
              instance
                .get(response.data.car.carModel, {
                  headers: {
                    Authorization: localStorage.getItem("token"),
                  },
                })
                .then(r => {
                  let currentCars =
                    JSON.parse(localStorage.getItem("currentCars")) ?? []
                  const car = response.data.car
                  const model = r.data
                  currentCars.push([car, model])

                  localStorage.setItem(
                    "currentCars",
                    JSON.stringify(currentCars)
                  )
                  localStorage.setItem(
                    "car",
                    JSON.stringify({ car: car, model: model })
                  )
                  navigate(props.navigateTo)
                })
            }
          }
        })
        .catch(error => {
          if (error.toString().includes("404")) {
            setCarDontExistError(true)
          } else if (error.toString().includes("403")) {
            if (props.currentPage == "profile") {
              navigate("/profile/add-car-already-associated")
            } else if (props.currentPage == "mycars") {
              navigate("/mycars/car-already-associated")
            }
          } else
            //navigate('/error');
          console.log("unhandled error")
        })
    }
    event.preventDefault()
  }

  const notValidField = (field, value) => {
    if (field === "vin") {
      var vinPattern = /^[A-HJ-NPR-Z0-9]{17}$/

      if (vinPattern.test(vin)) {
        setVinError(false)
        return false
      }
    }
    if (field === "licencePlate") {
      if (licence_plate.length > 15 || licence_plate.length === 0) {
        setLicensePlateError(true)
        return true
      }
      setLicensePlateError(false)
      return false
    }
  }

  const selectTab = tabNumber => {
    setSelectedTab(tabNumber)
  }

  useEffect(() => {
    if (0 < vin.length) {
      notValidField("vin", vin)
    }

    if (0 < licence_plate.length) {
      notValidField("licencePlate", licence_plate)
    }

    if (intl.locale === "fr-BE" || intl.locale === "nl-BE") {
      setLicencePlatePlaceholder("1-AAA-001")
    }
  }, [vin, licence_plate])

  return (
    <div>
      <SEO
        title={intl.formatMessage({ id: "pages_meta_title.profile.add-car" })}
      />

      <h1 className="text-center pt-4 mb-4">
        {intl.formatMessage({ id: "01_onboarding_add_car_plate.title" })}
      </h1>

      <div className="text-center mb-4">
        <ul
          className="nav nav-tabs nav-tabs-2-items mx-auto"
          id="myTab"
          role="tablist"
        >
          <li className="nav-item">
            <a
              className={
                selectedTab === 0 || selectedTab === 2
                  ? "nav-link active"
                  : "nav-link"
              }
              id="first-tab"
              data-toggle="tab"
              href="#first-tab-content"
              role="tab"
              aria-controls="chassis number"
              aria-selected="true"
              onClick={() => selectTab(0)}
            >
              {intl.formatMessage({
                id: "01_onboarding_add_car_plate.chassis_number",
              })}
            </a>
          </li>

          <li className="nav-item">
            <a
              className={selectedTab === 1 ? "nav-link active" : "nav-link"}
              id="second-tab"
              data-toggle="tab"
              href="#second-tab-content"
              role="tab"
              aria-controls="number plate"
              aria-selected="false"
              onClick={() => selectTab(1)}
            >
              {intl.formatMessage({
                id: "01_onboarding_add_car_plate.number_plate",
              })}
            </a>
          </li>
        </ul>
      </div>

      <div className="white-container w-auto mb-4">
        <div className="tab-content">
          <div
            className={selectedTab === 0 ? "tab-pane active" : "tab-pane"}
            id="first-tab-content"
            role="tabpanel"
            aria-labelledby="first-tab"
          >
            <Form>
              <div className="row">
                <div className="col-md-8">
                  <Form.Group>
                    <Form.Label className="required">
                      {intl.formatMessage({
                        id: "generic.forms.form_field_labels.chassis_number",
                      })}
                    </Form.Label>
                    <span className="icon-check ml-2 d-none"></span>
                    <Form.Control
                      type="text"
                      placeholder={intl.formatMessage({
                        id: "generic.forms.form_field_labels.vin_example",
                      })}
                      maxLength="17"
                      onFocus={e => (e.target.placeholder = "")}
                      onBlur={e =>
                        (e.target.placeholder = intl.formatMessage({
                          id: "generic.forms.form_field_labels.vin_example",
                        }))
                      }
                      onChange={e => {
                        setVin(e.target.value)
                      }}
                      value={vin}
                    />
                    {vinError && (
                      <small className="form-text form-validate-error">
                        <span>X</span>
                        {intl.formatMessage({
                          id:
                            "generic.forms.form_error_message.error_message_vin_invalid_format",
                        })}
                      </small>
                    )}
                    {carDontExistError && (
                      <small className="form-text form-validate-error">
                        <span>X</span>
                        {intl.formatMessage({
                          id:
                            "generic.forms.form_error_message.error_message_vin_not_found",
                        })}
                      </small>
                    )}
                  </Form.Group>

                  <p className="small">
                    <em>
                      {intl.formatMessage({
                        id: "1_2_1_1_onboarding_add_car_vin.help_text",
                      })}
                    </em>
                  </p>

                  <div className="mb-3">
                    <span className="icon-help fs-30 mr-2 align-middle"></span>
                    <span
                      className="dark-red link-nav"
                      onClick={() => selectTab(2)}
                    >
                      {intl.formatMessage({
                        id: "1_2_1_1_onboarding_add_car_vin.help_link",
                      })}
                    </span>
                  </div>

                  <div className="required-field">
                    *
                    {intl.formatMessage({
                      id: "generic.forms.form_mentions.required_fields",
                    })}
                  </div>
                </div>

                <div className="col-md-4 pt-label">
                  <button
                    type="submit"
                    className={
                      vinError || vin.length === 0
                        ? "disabled button red mx-auto w-100 mr-md-0"
                        : "button red mx-auto w-100 mr-md-0"
                    }
                    onClick={event => findCar(event, "vin", vin)}
                  >
                    {intl.formatMessage({
                      id: "generic.forms.form_buttons.add",
                    })}
                  </button>
                </div>
              </div>
            </Form>
          </div>
          <div
            className={selectedTab === 1 ? "tab-pane active" : "tab-pane"}
            id="second-tab-content"
            role="tabpanel"
            aria-labelledby="second-tab"
          >
            <Form>
              <Form.Group>
                <Form.Label className="required">
                  {intl.formatMessage({
                    id: "generic.forms.form_field_labels.number_plate",
                  })}
                </Form.Label>

                <div className="row">
                  <div className="col-md-8">
                    <div className="licence-plate">
                      <Form.Control
                        type="text"
                        className=""
                        placeholder={licencePlatePlaceholder}
                        id={licencePlatePrefix}
                        maxLength="15"
                        name="licence_plate"
                        onFocus={e => (e.target.placeholder = "")}
                        onBlur={e =>
                          (e.target.placeholder = licencePlatePlaceholder)
                        }
                        onChange={e => {
                          setLicencePlate(e.target.value)
                        }}
                        value={licence_plate}
                      />
                      <div className="licence__prefix">
                        {licencePlatePrefix}
                      </div>
                    </div>
                    {licensePlateError && (
                      <small className="form-text form-validate-error">
                        <span>X</span>
                        {intl.formatMessage({
                          id:
                            "generic.forms.form_error_message.error_message_plate_invalid_format",
                        })}
                      </small>
                    )}
                    {carDontExistError && (
                      <small className="form-text form-validate-error">
                        <span>X</span>
                        {intl.formatMessage({
                          id:
                            "generic.forms.form_error_message.error_message_plate_not_found",
                        })}
                      </small>
                    )}
                  </div>
                  <div className="col-md-4">
                    <button
                      type="submit"
                      className={
                        licensePlateError || licence_plate.length === 0
                          ? "disabled button red w-100 mx-auto"
                          : "button red w-100 mx-auto"
                      }
                      onClick={event =>
                        findCar(event, "licencePlate", licence_plate)
                      }
                    >
                      {intl.formatMessage({
                        id: "generic.forms.form_buttons.add",
                      })}
                    </button>
                  </div>
                </div>
              </Form.Group>
            </Form>
          </div>
          <div
            className={selectedTab === 2 ? "tab-pane active" : "tab-pane"}
            id="example-tab-content"
            role="tabpanel"
            aria-labelledby="example-tab-content"
          >
            <div className="row">
              <div className="col-md-6">
                <h2 className="mb-3">
                  <span className="icon-help fs-30 mr-2 align-middle"></span>{" "}
                  <span>
                    {intl.formatMessage({
                      id: "01_add_a_car_vin_example.title",
                    })}
                  </span>
                </h2>
                <p>
                  {intl.formatMessage({
                    id: "01_add_a_car_vin_example.description1",
                  })}
                </p>
                <p>
                  {intl.formatMessage({
                    id: "01_add_a_car_vin_example.description2",
                  })}
                </p>
              </div>
              <div className="col-md-6 text-center">
                <div className="mb-4">
                  {country === "NL" && (
                    <img
                      src={imgExampleNl}
                      alt={intl.formatMessage({
                        id: "01_add_a_car_vin_example.title",
                      })}
                      className="img-fluid"
                      width="446"
                    />
                  )}
                  {country === "BE" && (
                    <img
                      src={imgExampleBeNl}
                      alt={intl.formatMessage({
                        id: "01_add_a_car_vin_example.title",
                      })}
                      className="img-fluid"
                      width="446"
                    />
                  )}
                  {country === "LU" && (
                    <img
                      src={imgExampleLu}
                      alt={intl.formatMessage({
                        id: "01_add_a_car_vin_example.title",
                      })}
                      className="img-fluid"
                      width="446"
                    />
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div
                  className="button white back mx-auto ml-md-0"
                  onClick={() => selectTab(0)}
                >
                  {intl.formatMessage({
                    id: "generic.nav_buttons.backtoform_button",
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddCar
